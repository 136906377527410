<template>
  <div class="w-full max-w-md py-10 rounded-lg z-20">

    <!-- Header -->
    <div class="flex justify-center mb-10">
      <h1 class="font-bold text-2xl uppercase text-primary">
        {{ $t('auth.default-login-h1') }}
      </h1>
    </div>

    <!-- Form -->
    <AuthFormLogin class="mt-10"/>

  </div>
</template>

<script setup>
const {t} = useI18n()
useHead({
  title: t('auth.sign-in')
})
</script>